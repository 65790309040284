import React from "react";
import { Layout } from "@components/Layout";
import "@styles/Pages.scss";

interface CustomLocationInterface {
  pathname: string;
}

export default ({ location }: { location: CustomLocationInterface }) => {
  return (
    <div className="lanternsPage enterFade">
      <div className="exploreWishesWrap">
        ({" "}
        <img src={require("@assets/icons/tab.svg")} alt="Explore wishes icon" />
        <span> Explore wishes</span>)
      </div>
      <Layout
        withBackdrop={false}
        currentPath={location.pathname}
        exploreLanterns
      />
    </div>
  );
};
